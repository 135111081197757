import 'react-multi-carousel/lib/styles.css'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { SearchHotelHomeWidget } from '@/lib/searchHomeWidget'
import AuthContext from 'store/auth-context'
import Countrystyles from '../../../../../common/components/CountryDropdown/theme4/index.module.css'
import Image from 'next/image'
import b2bWidgetStyle from '../../../../../common/components/b2bwidgets/theme4/index.module.css'
import cf from '@/lib/utils/common'
import parse from 'html-react-parser'
import styles from './index.module.css'
import { useRouter } from 'next/router'
import DesktopHotelStylesTheme2 from '../../../../../common/components/widgets/hotelSearchWidget/theme8/index.module.css'
import PopupMobCss from '../../../../../common/components/ModalPopupComponent/theme8mobile/index.module.css'
import recentSearchStyle from '../../../../../common/components/RecentSearch/Theme8/index.module.css'
import paxCompStyle from '../../../../../common/components/PaxComp/Theme8/index.module.css'
import Link from '../../../../../common/Link.js'
import { getStorage } from '../../../../../common/lib/utils/localStorage'
import { VmsInputCheckbox } from '@/components/vms-components/InputCheckbox/index'
import CustomLink from '@/components/Link'
import moment from 'moment'

const RecentSearch = dynamic(
  () => import('../../../../../common/components/RecentSearch/Theme8/index')
)
const HotelSearchWidget = dynamic(
  () => import('../../../../../common/components/widgets/hotelSearchWidget')
)
const Carousel = dynamic(() => import('react-multi-carousel'), {
  loading: () => <p>loading...</p>,
  ssr: false,
})
const PromotionBanner = dynamic(() => import('../../../promotionBanner/index'))

import {
  myGreetingMySwiper,
  myGreetingMySwiper2,
  myGreetingElevatenew,
  myGreetingCruiseHolidays,
  myGreetingChild_featureSwiper,
  myGreetingTestimonial_data,
  myGreetingMyclientSwiper,
  myGreetingOffermain,
  myGreetingNewlyadded,
  myGreetingMostbooked,
  myGreetingHoneymoonpkg,
  myGreetingExploremain,
  myGreetingBudgetSwiper,
  offerSwiper,
  myGreetingPopularSwiper,
  myGreetingNetworkslider,
  myGreetingCardSwiper,
} from '../../../../public/js/carousalFun'
import dynamic from 'next/dynamic'

export default function Theme8Flow(props) {
  const ctx = useContext(AuthContext)
  const [activeItemIndex, setActiveItemIndex] = useState(0)
  const wrapperRef = useRef(null)
  const [expandHotelNameBoolean, setExpandHotelName] = useState(false)
  const [getHotelAutoSuggestList, setHotelAutoSuggestList] = useState([])
  const [initHotelData, setInitHotelData] = useState([])
  // const [homeBodyDesktop, setHomeBodyDesktop] = useState([])
  // const [homeBodyMobile, setHomeBodyMobile] = useState([])
  const [homeBody, setHomeBody] = useState([])
  const [firstNobannerId, setFirstNoBannerId] = useState(null)
  const [lastNobannerId, setLastNoBannerId] = useState(null)
  const [hotelDefultDays, setHotelDefultDays] = useState(0)
  const [userProfile, setUserProfile] = useState('')
  const [SerachWidget, setSerachWidget] = useState('')
  const [SerachWidMob, setSerachWidMob] = useState('')
  const [isActive, setIsActive] = useState('')
  const [getrecentSearchList, setrecentSearchList] = useState([])
  const [recentSearchData, setRecentSearchData] = useState([])
  const [isDesktopDefaultImg, setIsDesktopDefaultImg] = useState(false)
  const [isMobDefaultImg, setIsMobDefaultImg] = useState(false)
  const [state, setState] = useState({
    showBreakfast: false,
    showFreeCancellation: false,
    recentSearchClicked: true,
    hotelName: '',
    getDestinationId: '',
    getSearchType: 6,
    property_count: '',
    dest_type: { code: '', text: '' },
  })

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      // slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  }

  useEffect(() => {
    let userToken = cf.getCookieTocken('userToken')
    cf.getUserProfile(userToken, props.site_id).then((res) => {
      if (res.status === true) {
        setUserProfile(ctx?.user)
      } else {
        setUserProfile(res)
      }
    })
    updateHomeBody()
    if (props?.home?.data?.data?.body?.desktop) {
      // setHomeBodyDesktop(props.home.data.data.body.desktop)

      /**FIND FIRST AVILLABLE WIDGET**/

      let defaultService = props.home.data.data.header.menu.filter(
        (ele) => ele.status.toLowerCase() == 'active'
      )
      if (defaultService.length > 0) {
        setSerachWidget(defaultService[0].code)
        setIsActive(defaultService[0].code)
      }

      let defaultImgDesktop = props.home.data.data.body.desktop.filter(
        (ele) => ele.type == 'image_carousal'
      )
      if (defaultImgDesktop.length == 0) {
        setIsDesktopDefaultImg(true)
      }

      let defaultImgMob = props?.home?.data?.data?.body?.mobile.filter(
        (ele) => ele.type == 'image_carousal'
      )
      if (defaultImgMob.length == 0) {
        setIsMobDefaultImg(true)
      }

      let hotelIndex = props.home.data.data.body.desktop.findIndex(
        (items) => items.type === 'hotel_search'
      )
      if (hotelIndex > -1) {
        setHotelDefultDays(props.home.data.data.body.desktop[hotelIndex].default_days)
      }
    }
    // if (props?.home?.data?.data?.body?.mobile) setHomeBodyMobile(props.home.data.data.body.mobile)
    const style = document.createElement('style')
    if (
      !cf.isValueEmpty(props) &&
      !cf.isValueEmpty(props.home) &&
      !cf.isValueEmpty(props.home.data)
    ) {
      style.innerHTML = props.home.data.data.css
      document.head.appendChild(style)
    }
    setActiveItemIndex(activeItemIndex)
    if (!cf.isValueEmpty(props) && !cf.isValueEmpty(props.home) && props.home.status === true) {
      if (
        !cf.isValueEmpty(props.home.data.data) &&
        !cf.isValueEmpty(props.home.data.data.body) &&
        !cf.isValueEmpty(props.home.data.data.body.desktop) &&
        props.home.data.data.body.desktop.length > 0
      ) {
        let hotelIndex = props.home.data.data.body.desktop.findIndex(
          (items) => items.type === 'hotel_search'
        )

        if (hotelIndex !== -1) {
          if (
            !cf.isValueEmpty(props.home.data.data.body.desktop[hotelIndex].locations) &&
            props.home.data.data.body.desktop[hotelIndex].locations.length > 0
          ) {
            setInitHotelData([...props.home.data.data.body.desktop[hotelIndex].locations])
            setHotelAutoSuggestList([...props.home.data.data.body.desktop[hotelIndex].locations])
          }
        }
      }
    }
    if (ctx?.isLoggedIn == true) {
      setTimeout(() => {
        document?.getElementById('signupContainer')?.style.display = 'none'
      }, 5000)
    }

    let LocalSearch = getStorage('hotelSearchKey')

    let LocalSearchAll = getStorage('hotelSearchKeyAll')

    if (LocalSearchAll != null) {
      setRecentSearchData(JSON.parse(LocalSearchAll))
    }
    if (LocalSearch != null && LocalSearchAll == null) {
      setRecentSearchData([JSON.parse(LocalSearch)])
    }

    document.addEventListener('click', handleClickOutside, false)
    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [])

  useEffect(() => {
  
    if (recentSearchData && recentSearchData.length > 0) {
      recentSearchData.map((element, index) => {
      
        if (index == 0) {
          setState({
            showBreakfast: element?.is_breakfast,
            showFreeCancellation: element?.is_free_cancellation,
          })
        }
      })
    }
  }, [recentSearchData])

  useEffect(() => {
    try {
      // if (props?.home?.data?.data?.body?.desktop) {
      //   setHomeBodyDesktop(props.home.data.data.body.desktop)
      // }

      // if (props?.home?.data?.data?.body?.mobile) {
      //   setHomeBodyMobile(props.home.data.data.body.mobile)
      // }

      updateHomeBody()

      if (window.location.pathname == '/hotel' || window.location.pathname == '/hotels') {
        setSerachWidget('hotel')
        setSerachWidMob('hotel')
        setIsActive('hotel')
      }
      if (router.query.type == 'homestay') {
        setSerachWidget('homestay')
        setSerachWidMob('homestay')
        setIsActive('homestay')
      }

      setTimeout(() => {
        myGreetingMySwiper(),
          myGreetingMySwiper2(),
          myGreetingElevatenew(),
          myGreetingCruiseHolidays(),
          myGreetingChild_featureSwiper(),
          myGreetingTestimonial_data(),
          myGreetingMyclientSwiper(),
          myGreetingOffermain(),
          myGreetingNewlyadded(),
          myGreetingMostbooked(),
          myGreetingHoneymoonpkg(),
          myGreetingExploremain(),
          myGreetingBudgetSwiper(),
          offerSwiper(),
          myGreetingPopularSwiper(),
          myGreetingNetworkslider(),
          myGreetingCardSwiper()
      }, 100)
    } catch (error) {
      console.log(error)
    }
  }, [query])

  const updateHomeBody = () => {
    const homeBodyRes = props?.isMobile
      ? props?.home?.data?.data?.body?.mobile
      : props?.home?.data?.data?.body?.desktop
    const firstNobanner = homeBodyRes.find((item) => item.banner_data === 'nobanner')
    const lastNobanner = homeBodyRes.filter((item) => item.banner_data === 'nobanner').pop()
    const firstNobannerOrder = firstNobanner ? firstNobanner?.order : null
    const lastNobannerOrder = lastNobanner ? lastNobanner?.order : null
    setHomeBody(homeBodyRes)
    setFirstNoBannerId(firstNobannerOrder)
    setLastNoBannerId(lastNobannerOrder)
  }

  const getAutoSuggestDataFunc = (value, section, type, event) => {
    setState((prevState) => ({
      ...prevState,
      showFreeCancellation: state.showFreeCancellation,
      showBreakfast: state.showBreakfast,
    }))

    switch (section) {
      case 'hotel':
        if (value.length > 0) {
          SearchHotelHomeWidget({ autoSuggest: value }).then((res) => {
            if (!cf.isValueEmpty(res) && res.status === true && !cf.isValueEmpty(res.data)) {
              if (res.data.status === 'success' && !cf.isValueEmpty(res.data.result)) {
                setHotelAutoSuggestList([...res.data.result.records])
                setrecentSearchList([...res.data.result.recent_search])
                setExpandHotelName(true)
              }
            }
          })
        } else {
          setHotelAutoSuggestList([...initHotelData])
          setExpandHotelName(true)
        }
        break
      default:
        break
    }
  }
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      if (expandHotelNameBoolean === true) setExpandHotelName(false)
    }
  }

  const imageLoader = ({ src }) => {
    return `${'/shared/api/media/' + props.site_id + '/' + src}`
  }

  const router = useRouter()
  const query = router.query.index
  const stays_villas = router.query.type == 'homestay'

  const copyCouponCode = (id) => {
    if (id) {
      var copyText = document.getElementById(id).innerHTML
      if (copyText) {
        navigator.clipboard.writeText(copyText)
        var copycouponcodeId = 'copycouponcode_' + id
        document.getElementById(copycouponcodeId).innerHTML = 'Copied'
        setTimeout(function () {
          document.getElementById(copycouponcodeId).innerHTML = 'Copy Code'
        }, 3000)
      }
    }
  }

  const updateRecentSearch = (element) => {
    console.log('elementrecent==>', element)

    // Parse the checkin date
    let checkinDate = moment(element.checkin, 'DD-MM-YYYY')

    // Validate checkin date - set to current date if in the past
    let currentDate = moment()
    if (checkinDate.isBefore(currentDate, 'day')) {
      checkinDate = currentDate // Set to current date if past
    }

    // Update state with formatted dates and other data
    setState({
      hotelName: element?.search_text,
      getDestinationId: element?.destination_id,
      getSearchType: element?.search_type ? element?.search_type : 6,
      property_count: element?.property_count,
      dest_type: {
        code: element?.dest_type?.code,
        text: element?.dest_type?.text,
      },
      recentSearchClicked: true,
      checkin: checkinDate.format('DD-MMM-YYYY'), // formatted date
      checkout: element?.checkout,
      selectedRoomDetails: element?.selectedRoomDetails,
      roomList: element?.roomList,
      showBreakfast: element?.is_breakfast,
      showFreeCancellation: element?.is_free_cancellation,
    })
  }

  const onClickBreakfast = () => {
    setState((prevState) => ({
      ...prevState,
      showBreakfast: !prevState.showBreakfast,
    }))
  }

  const onClickFreeCancellation = () => {
    setState((prevState) => ({
      ...prevState,
      showFreeCancellation: !prevState.showFreeCancellation,
    }))
  }

  const onClickRecentSearch = (value) => {
    setState({ recentSearchClicked: value })
  }

  return (
    <div className={styles.home_container}>
      <div style={{ margin: 0 }}>
        {homeBody.map((res, i) => {
          return (
            <div key={'homeBodyDsktp' + i}>
              {!cf.isValueEmpty(res.type) &&
                res.type == 'image_carousal' &&
                (res.banners && res.banners.length > 0 ? (
                  <div
                    className={`${props.isMobile ? styles.carousal_container : ''}`}
                    style={{ margin: 0 }}
                  >
                    <Carousel
                      swipeable={false}
                      draggable={false}
                      showDots={false}
                      responsive={responsive}
                      ssr={false} // means to render carousel on server-side.
                      infinite={true}
                      autoPlay={props.isMobile ? false : false}
                      autoPlaySpeed={3000}
                      keyBoardControl={true}
                      customTransition="all .5"
                      transitionDuration={500}
                      containerClass={styles.carouse_container2}
                      //  containerClass="carousel-container"
                      // deviceType={this.props.deviceType}
                      dotListClass="custom-dot-list-style"
                      itemClass="carousel-item-padding-40-px"
                    >
                      {!cf.isValueEmpty(res) &&
                        !cf.isValueEmpty(res.banners) &&
                        res.banners.length > 0 &&
                        res.banners.map((imagedata, index) => {
                          return (
                            <div
                              style={{ margin: 0 }}
                              key={'banner' + index}
                              className={styles.img_banner_home2}
                            >
                              <CustomLink href={imagedata.banner_url}>
                              
                                <Image
                                  loader={imageLoader}
                                  src={props?.isMobile ? imagedata?.r_image : imagedata?.image}
                                  width={500}
                                  layout="fill"
                                  alt="image-carousel"
                                />
                              </CustomLink>
                            </div>
                          )
                        })}
                    </Carousel>
                  </div>
                ) : (
                  <div className={styles.nobanner}></div>
                ))}
            </div>
          )
        })}
      </div>

      <div className={`${styles.mainWidget_container}`}>
      
        <div className={`${styles.widget_parent} ${styles.widget_parent_container}`}>
            <div className={`${styles.hotel_header}`} >
            <h1 className={`${styles.hotel_header__title}`} >Discover Akasaair hotels</h1>
            <p className={`${styles.hotel_header__subtitle}`} >Your gateway to budget and luxury stays worldwide!</p>
          </div>
          <div
            className={`${styles.widget_container} ${
              SerachWidget === 'hotel' ? styles.hotel_widget : styles.holiday_widget
            }`}
          >
            <div className={styles.search_widget_container}>
              <div className={styles.search}>
                <div className={styles.holidaymaintabnavbarrow}>
                  <div className={styles.holidaycarouselcontainer}>
                    <nav className={styles.navbartab}>
                      <ul>
                        {props?.home?.data?.data?.header?.menu
                          .filter((ele) => ele.status.toLowerCase() == 'active')
                          .slice(0, 7)
                          .map((res, i) => {
                            return (
                              <>
                                {res.code != 'others' ? (
                                  <Link title={res.text} key={res.text} href={res.url} className="">
                                    <li
                                      className={
                                        SerachWidget === res.code ||
                                        (SerachWidget == 'holiday' && res.code == 'holidays')
                                          ? `${styles.activetab}`
                                          : `${styles.inactive}`
                                      }
                                      onClick={() => {
                                        setSerachWidget(
                                          res.code == 'holidays' ? 'holiday' : res.code
                                        )
                                      }}
                                    >
                                      <div className={styles.service_icon}>
                                        {SerachWidget === res.code ? (
                                          <img
                                            src={`images/${res.code == 'holidays' ? 'holiday' : res.code}_active.svg`}
                                            alt={res.code}
                                          />
                                        ) : res.icon == '' ? (
                                          <img src="images/forexImg.svg" alt={res.code} />
                                        ) : (
                                          <img
                                            src={`${
                                              '/shared/api/media/' + props.site_id + '/' + res.icon
                                            }`}
                                            alt={res.code}
                                          />
                                        )}
                                      </div>
                                      <span className={styles.service_label}>{res.text}</span>
                                    </li>
                                  </Link>
                                ) : (
                                  <a
                                    title={res.text}
                                    key={res.text}
                                    href={res.url}
                                    // target="_blank"
                                    className=""
                                  >
                                    <li
                                      className={
                                        SerachWidget == 'others' && res.code == 'others'
                                          ? `${styles.activetab}`
                                          : `${styles.inactive}`
                                      }
                                    >
                                      <div className={styles.service_icon}>
                                        {res.icon == '' ? (
                                          <img src="images/forexImg.svg" alt={res.code} />
                                        ) : (
                                          <img
                                            src={`${
                                              '/shared/api/media/' + props.site_id + '/' + res.icon
                                            }`}
                                            alt={res.code}
                                          />
                                        )}
                                      </div>
                                      {res.text}
                                    </li>
                                  </a>
                                )}
                              </>
                            )
                          })}
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>

            <div>
              {homeBody.map((res, i) => {
                return (
                  <div key={'homeBodyDsktp' + i}>
                    {(SerachWidget === 'hotel' || SerachWidget === 'homestay') &&
                      res.type == 'hotel_search' && (
                        <div>
                          <HotelSearchWidget
                            pageTheme={props?.home?.data?.site?.site_theme}
                            isSRP={false}
                            isOffline={false}
                            styles={DesktopHotelStylesTheme2}
                            b2bStyles={b2bWidgetStyle}
                            getAutoSuggestValue={(value) => getAutoSuggestDataFunc(value, 'hotel')}
                            expandHotelNameBoolean={expandHotelNameBoolean}
                            getDefaultDays={hotelDefultDays}
                            getAutoSuggestList={getHotelAutoSuggestList}
                            getRecentSearchList={getrecentSearchList}
                            expandHotelNameBooleanfunc={(value) => setExpandHotelName(value)}
                            homeData={!cf.isValueEmpty(initHotelData) ? initHotelData : []}
                            dateFormat={props?.home?.data?.site?.date_format}
                            userProfile={userProfile}
                            Countrystyles={Countrystyles}
                            PopupMobCss={PopupMobCss}
                            PopularDestination={false}
                            paxCompStyle={paxCompStyle}
                            updateRecentSearch={updateRecentSearch}
                            state={state}
                            stays_villas={stays_villas}
                            onClickRecentSearch={onClickRecentSearch}
                            isMobile={props?.isMobile}
                            recentSearchData={recentSearchData}
                          />
                        </div>
                      )}
                  </div>
                )
              })}
            </div>
            {!props.isMobile && (
              <div className={styles.checkbox_container}>
                <label className={styles.checkbox_label}>
                  <VmsInputCheckbox
                    id="freeCancellation"
                    checked={state.showFreeCancellation}
                    onClick={onClickFreeCancellation}
                    normallabel={'Free cancellation'}
                  />
                </label>

                <label className={styles.checkbox_label}>
                  <VmsInputCheckbox
                    id="freebreakfast"
                    checked={state.showBreakfast}
                    onClick={onClickBreakfast}
                    normallabel={'Free breakfast'}
                  />
                </label>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Recent Search Section starts ==> */}
      {recentSearchData.length > 0 && (
        <div>
          <RecentSearch
            pageTheme={props?.home?.data?.site?.site_theme}
            isSRP={false}
            isOffline={false}
            styles={recentSearchStyle}
            b2bStyles={b2bWidgetStyle}
            getAutoSuggestValue={(value) => getAutoSuggestDataFunc(value, 'hotel')}
            expandHotelNameBoolean={expandHotelNameBoolean}
            getDefaultDays={hotelDefultDays}
            getAutoSuggestList={getHotelAutoSuggestList}
            getRecentSearchList={getrecentSearchList}
            expandHotelNameBooleanfunc={(value) => setExpandHotelName(value)}
            homeData={!cf.isValueEmpty(initHotelData) ? initHotelData : []}
            dateFormat={props?.home?.data?.site?.date_format}
            userProfile={userProfile}
            Countrystyles={Countrystyles}
            PopupMobCss={PopupMobCss}
            recentSearchData={recentSearchData}
            updateRecentSearch={updateRecentSearch}
          />
        </div>
      )}
      {/* Recent Search Section end ==> */}
      <div>
        {homeBody.map((res, i) => {
          return (
            <div key={'homeBodyDsktp' + i}>
              {res?.type == 'promotion_banner' ? (
                <PromotionBanner
                  isMobile={props?.isMobile}
                  responsive={responsive}
                  res={res}
                  imageLoader={imageLoader}
                  cssstyles={styles}
                />
              ) : (
                <div
                  className={
                    firstNobannerId == res.order
                      ? styles.firstnobanner
                      : res.order == lastNobannerId
                        ? styles.lastnobanner
                        : ''
                  }
                >
                  {!cf.isValueEmpty(res.html)
                    ? parse(res.html, {
                        replace: ({ attribs }) => {
                          if (attribs && attribs.copycouponcode === 'copy') {
                            return (
                              <p
                                class="copyZth"
                                copycouponcode="copy"
                                copycouponcodevalue={attribs.copycouponcodevalue}
                                copycouponcodenumber={attribs.copycouponcodenumber}
                                id={attribs.id}
                                onClick={() => copyCouponCode(attribs.copycouponcodenumber)}
                              >
                                Copy Code
                              </p>
                            )
                          }
                        },
                      })
                    : ''}
                </div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}
