import Theme5 from "./theme5";
import Theme4 from "./theme5";

export default function CarSearchWidget(props) {
  const theme = props.pageTheme;

  switch (theme) {
    case "theme4":
      return <Theme4 {...props} />;
    case "theme5":
      return <Theme5 {...props} />;
    case "theme7":
      return <Theme5 {...props} />;
    default:
      return <Theme5 {...props} />;
  }
}
