import { InputCheckbox } from "@vernost_dev/vms-react-components/dist/InputCheckbox";

export const VmsInputCheckbox = ({
  id,
  type = "primary",
  labelComponent,
  onChange,
  onClick,
  disabled=false,
  checked=false,
  value,
  partialSelection=false,
  boldLabelOnCheckboxSelection=true,
  labelClassName,
  labelStyle,
  containerClassName,
  containerStyle,
  normallabel,
  label,
  islinkwithtext="false",
}) => {
  return (
    <InputCheckbox
      id={id}
      type={type}
      labelComponent={labelComponent}
      onChange={onChange}
      onClick={onClick}
      disabled={disabled}
      checked={checked}
      value={value}
      partialSelection={partialSelection}
      boldLabelOnCheckboxSelection={boldLabelOnCheckboxSelection}
      labelClassName={labelClassName}
      labelStyle={labelStyle}
      containerClassName={containerClassName}
      containerStyle={containerStyle}
      normallabel={normallabel}
      label={label}
      islinkwithtext={islinkwithtext}
    />
  );
};
