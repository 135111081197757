export  function myGreetingMySwiper() {
    var swiper = new Swiper('.mySwiper', {
      // slidesPerView: 4,
      // spaceBetween: 30,
      // slidesPerGroup: 4,
      // loop: true,
      // autoplay: {
      //     delay: 2000,
      //     disableOnInteraction: false,
      // },
      autoplay: {
        slidesPerView: 4,
        spaceBetween: 30,
        disableOnInteraction: false,
      },
      // pagination: {
      //     el: ".pagination",
      //     dynamicBullets: false,
      //     clickable: true
      //   },
      breakpoints: {
        640: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
      },
    })
  }

 



export function myGreetingMySwiper2() {
    var swiper = new Swiper('.mySwiper2', {
      slidesPerView: 1,
      spaceBetween: 0,
      slidesPerGroup: 1,
      loop: true,
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
      },
    })
  }

  export  function offerSwiper() {
    var swiper = new Swiper('.why_holidayCont', {
      loop: true,
      pagination: {
        el: '.swiper-pagination', 
        clickable: true, 
    },
      autoplay: {
        slidesPerView: 4,
        spaceBetween: 30,
        delay: 5000,
        disableOnInteraction: false,
      },
      speed: 600,
      breakpoints: {
        640: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
      },
    })
  }


export function myGreetingElevatenew() {
    var swiper = new Swiper('.elevate-new', {
      // slidesPerView: 3,
      // spaceBetween: 30,
      // slidesPerGroup: 3,
      // loop: true,
      // autoplay: {
      //     delay: 2000,
      //     disableOnInteraction: false,
      // },
      autoplay: {
        slidesPerView: 3,
        spaceBetween: 30,
        disableOnInteraction: false,
      },
      breakpoints: {
        640: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
    })
  }

// theme2 courosal js start//

export function myGreetingCruiseHolidays() {
    var swiper = new Swiper('.CruiseHolidays', {
      autoplay: {
        slidesPerView: 4,
        spaceBetween: 10,
        disableOnInteraction: false,
      },
      breakpoints: {
        360: {
          slidesPerView: 2,
          spaceBetween: 0,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 10,
        },
      },
    })
  }



export function myGreetingChild_featureSwiper() {
    var swiper = new Swiper('.child_featureSwiper', {
      autoplay: {
        slidesPerView: 4,
        spaceBetween: 10,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
      },
      breakpoints: {
        360: {
          slidesPerView: 2,
          spaceBetween: 0,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 10,
        },
      },
    })
  }


export function myGreetingTestimonial_data() {
    var swiper = new Swiper('.testimonial_data', {
      slidesPerView: 1,
      spaceBetween: 0,
      slidesPerGroup: 1,
      loop: true,
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
      },
    })
  }


export function myGreetingMyclientSwiper() {
    var swiper = new Swiper('.myclientSwiper', {
      autoplay: {
        slidesPerView: 6,
        spaceBetween: 10,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
      },
      breakpoints: {
        360: {
          slidesPerView: 3,
          spaceBetween: 0,
        },
        1024: {
          slidesPerView: 6,
          spaceBetween: 10,
        },
      },
    })
  }

export function myGreetingOffermain() {
    var swiper = new Swiper('.offermain', {
      autoplay: {
        // delay: 3000,
        // slidesPerView: 4,
        // spaceBetween: 20,
        disableOnInteraction: false,
      },
      breakpoints: {
        360: {
          slidesPerView: 1.3,
          spaceBetween: 10,
        },
        640: {
          slidesPerView: 1.3,
          spaceBetween: 10,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 10,
        },
      },
    })
  }


export function myGreetingNewlyadded() {
    var swiper = new Swiper('.newlyadded', {
      autoplay: {
        // slidesPerView: 5,
        // spaceBetween: 10,
        disableOnInteraction: false,
      },
      breakpoints: {
        360: {
          slidesPerView: 1.4,
          spaceBetween: 10,
        },
        640: {
          slidesPerView: 1.4,
          spaceBetween: 10,
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 10,
        },
      },
    })
  }


export function myGreetingMostbooked() {
    var swiper = new Swiper('.mostbooked', {
      autoplay: {
        // slidesPerView: 5,
        // spaceBetween: 10,
        disableOnInteraction: false,
      },
      breakpoints: {
        360: {
          slidesPerView: 1.6,
          spaceBetween: 10,
        },
        640: {
          slidesPerView: 1.6,
          spaceBetween: 10,
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 10,
        },
      },
    })
  }


export function myGreetingHoneymoonpkg() {
    var swiper = new Swiper('.honeymoonpkg', {
      autoplay: {
        // slidesPerView: 7,
        // spaceBetween: 10,
        disableOnInteraction: false,
      },
      breakpoints: {
        360: {
          slidesPerView: 3.7,
          spaceBetween: 10,
        },
        640: {
          slidesPerView: 3.7,
          spaceBetween: 10,
        },
        1024: {
          slidesPerView: 7,
          spaceBetween: 10,
        },
      },
    })
  }


// Explore holiday mobile

export function myGreetingExploremain() {
    var swiper = new Swiper('.exploremain', {
      // slidesPerView: 4,
      // spaceBetween: 10,
      autoplay: {
        disableOnInteraction: false,
      },
      breakpoints: {
        360: {
          slidesPerView: 1.3,
          spaceBetween: 10,
        },
        640: {
          slidesPerView: 1.3,
          spaceBetween: 10,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 10,
        },
      },
    })
  }

export function myGreetingBudgetSwiper() {
    var swiper = new Swiper('.budgetSwiper', {
      slidesPerView: 3,
      spaceBetween: 20,
      // slidesPerGroup: 4,
      // loop: true,
      // autoplay: {
      //     delay: 2000,
      //     disableOnInteraction: false,
      // },
      autoplay: {
        // slidesPerView: 4,
        // spaceBetween: 30,
        disableOnInteraction: false,
      },
      breakpoints: {
        640: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      },
    })
  }

  // This is for existing requirement. This may be used in future.

// export function myGreetingPopularSwiper() {

//   var swiper = new Swiper('.popularSwiper', {
  
//     loop: false,
//     autoplay: false, 
//     breakpoints: {
//       280: {
//         slidesPerView: 1.5,
//         spaceBetween: 10,
//       },
//       1024: {
//         slidesPerView: 4,
//         spaceBetween: 11,
//         navigation: {
//           nextEl: '.swiper-button-next',
//           prevEl: '.swiper-button-prev',
//         },
      
//       },
    
//     },
//   })
// }

export function myGreetingPopularSwiper() {
  const swiperContainer = document.querySelector('.popularSwiper');
  const swiperWrapper = swiperContainer.querySelector('.swiper-wrapper');
  const slides = swiperWrapper.querySelectorAll('.swiper-slide');
  const prevArrow = document.querySelector('.offerSwiper-button-prev');
  const nextArrow = document.querySelector('.offerSwiper-button-next');
  const isMobile = window.innerWidth <= 768;

  if (slides.length <= 4 && !isMobile) {
    swiperContainer.classList.add('static-swiper');
    prevArrow.style.display = 'none';
    nextArrow.style.display = 'none';

    slides.forEach(slide => {
      slide.style.flex = '0 0 289px';
      slide.style.maxWidth = '289px';
    });

    return;
  } else {
    prevArrow.style.display = 'flex';
    nextArrow.style.display = 'flex';
  }

  var swiper = new Swiper('.popularSwiper', {
    loop: false,
    autoplay: false,
    slidesPerView: isMobile ? 1.5 : 4,
    spaceBetween: isMobile ? 10 : 11,
    slidesPerGroup: 1,
    navigation: {
      nextEl: '.offerSwiper-button-next',
      prevEl: '.offerSwiper-button-prev',
    },
    breakpoints: {
      280: {
        slidesPerView: 1.4,
        spaceBetween: 15,
      },
      768: {
        slidesPerView: 2.5,
        spaceBetween: 10,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 11,
      },
    },
  });
}

export function myGreetingNetworkslider() {
  const swiperContainer = document.querySelector('.networkslider');
  const swiperWrapper = swiperContainer.querySelector('.swiper-wrapper');
  const slides = swiperWrapper.querySelectorAll('.swiper-slide');
  const prevArrow = document.querySelector('.offerSwiper-button-prevs');
  const nextArrow = document.querySelector('.offerSwiper-button-nexts');
  const isMobile = window.innerWidth <= 768;

  if (slides.length <= 4 && !isMobile) {
    swiperContainer.classList.add('static-swiper');
    prevArrow.style.display = 'none';
    nextArrow.style.display = 'none';

    slides.forEach(slide => {
      slide.style.flex = '0 0 289px';
      slide.style.maxWidth = '289px';
    });

    return;
  } else {
    prevArrow.style.display = 'flex';
    nextArrow.style.display = 'flex';
  }

  var swiper = new Swiper('.networkslider', {
    loop: false,
    autoplay: false,
    slidesPerView: isMobile ? 1.5 : 4,
    spaceBetween: isMobile ? 10 : 11,
    slidesPerGroup: 1,
    navigation: {
      nextEl: '.offerSwiper-button-nexts',
      prevEl: '.offerSwiper-button-prevs',
    },
    breakpoints: {
      280: {
        slidesPerView: 1.4,
        spaceBetween: 15,
      },
      768: {
        slidesPerView: 2.5,
        spaceBetween: 10,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 11,
      },
    },
  });
}

  // This is for existing requirement. This may be used in future.

// export function myGreetingNetworkslider() {
//   var swiper = new Swiper('.networkslider', {

//     loop: true,
//     autoplay: false, 
//     breakpoints: {
//       280: {
//         slidesPerView: 1.4,
//         spaceBetween: 15,
//       },
//       1024: {
//         slidesPerView: 4,
//         spaceBetween: 11,
//         navigation: {
//           nextEl: '.swiper-button-next',
//           prevEl: '.swiper-button-prev',
//         },
//       },
    
//     },
//   })
// }

export function myGreetingCardSwiper() {
  var swiper = new Swiper('.cardSwiper', {
    loop: true,
    pagination: {
      el: '.swiper-pagination', 
      clickable: true, 
  },
    autoplay: {
        delay: 2000,
        disableOnInteraction: false,
    },
    autoplay: {
      disableOnInteraction: false,
    },
    breakpoints: {
      640: {
        slidesPerView: 1,
        // spaceBetween: 11,
      },
      800: {
        slidesPerView: 2.5,
        spaceBetween: 11,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 11,
      },
    
    },
  })
}

export function copyCodeClipBoard(id) {
  var copyText = document.getElementById(id).innerHTML
  navigator.clipboard.writeText(copyText)
}
